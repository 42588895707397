import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  TextArea,
  Label,
  Button,
  Input,
  Icon
} from 'semantic-ui-react'

const CardPatientPanelUX = (props: any) => {
    return(
      <div
        style={{width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
        <div
          style={{"height":"30px","marginBottom":"-24px","position":"relative"}}>
          {props.buttonToggleTabMenu}
        </div>
        <div
          style={{display: "flex", width : "100%", paddingTop: "35px", ...(!props.hasDebt && { display: "none" }) }}>
          {props.totalBalancePrice}
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "10px", height: "250px", width: "auto"}}>
          {props.profileImage}
        </div>
        <div>
          
          <div
            style={{ display: "grid",alignItems: "center", marginTop:"-50px",float:'right'}}>
            
            <div
              style={{display: props.adrList ?  "flex" : "none" ,justifyContent: "row", alignItems: "center", marginLeft:"40px"}}>
              
              <div
                style={{padding: "2px"}}>
                {props.otherImage}
              </div>
              <div
                style={{padding: "2px"}}>
                {props.foodImage}
              </div>
              <div
                style={{padding: "2px"}}>
                {props.drugImage}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{padding: "10px 10px 0px 10px",marginTop:"10px"}}>
          {props.statusAndAction}
        </div>
        <div
          style={{ fontSize: "0.75rem", padding: "0px 13px 0px 35px", minWidth: "8.75rem", display: props.showCreateAppointment ? "grid":"none"}}>
          
          <Button
            color="blue"
            id="CardPatientPanel-Button-CreateAppointment"
            onClick={props.goToCreateAppointment}
            size="mini"
            style={{width:"100%"}}>
            
            <div
              style={{display:"flex", gap:"10px",alignItems:"center"}}>
              
              <Icon
                name="calendar alternate">
              </Icon>
              <div>
                ทำนัดหมาย
              </div>
            </div>
          </Button>
        </div>
        <div
          style={{...(props.actionButton&&{padding: "10px",marginTop:"10px"}), ...(props.hideTelemed && { display: "none"})}}>
          {props.actionButton}
        </div>
        <div
          style={{display: props.showDentalHistory ? "":"none",padding: "0px 10px", marginTop:"10px"}}>
          
          <Button
            color="pink"
            disabled={!props.showDentalHistory}
            fluid={true}
            id="CardPatientPanel-Button-DentalHistory"
            onClick={props.goToDentalHistory}
            size="mini"
            style={{width:"100%"}}>
            <div
              style={{display:"flex", gap:"20px",alignItems:"center"}}>
              
              <img
                src="/static/images/tooth-icon.png"
                style={{width: "10px", marginRight: "4px",marginLeft:"2px"}}>
              </img>
              <label>
                Dental History
              </label>
            </div>
          </Button>
        </div>
        <div
          style={{"width":"100%","height":"100%" , position:"relative", "paddingLeft":"10px",paddingTop:"10px"}}>
          
          <div
            style={{display: "flex", width : "100%"}}>
            
            <div
              style={{ width: "35%"}}>
              {props.hnLable}
            </div>
            <div
              style={{width: "65%"}}>
              {props.hn}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px"}}>
            
            <div
              style={{ width: "15%"}}>
              ชื่อ
            </div>
            <div
              style={{ width: "20%"}}>
              {props.oldNameIcon}
            </div>
            <div
              style={{ width: "65%"}}>
              {props.patientName}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px"}}>
            
            <div
              style={{ width: "35%", display: "flex", alignItems: "center", }}>
              เพศ
            </div>
            <div
              style={{ width: "32%", display: "flex", alignItems: "center",}}>
              {props.gender}
            </div>
            <div
              style={{ width: "33%", display: "flex", alignItems: "center",  ...(!props.isShowPregnancy && { display: "none"})}}>
              {props.pregnancyImage}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px"}}>
            
            <div
              style={{ width: "35%"}}>
              อายุ
            </div>
            <div
              style={{ width: "65%"}}>
              {props.age}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px"}}>
            
            <div
              style={{ width: "35%"}}>
              วันเกิด
            </div>
            <div
              style={{ width: "65%"}}>
              {props.birthdate}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px"}}>
            
            <div
              style={{ width: "35%"}}>
              เบอร์โทร
            </div>
            <div
              style={{ width: "65%"}}>
              {props.tel_mobile}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px", }}>
            
            <div
              style={{ width: "35%"}}>
              E-mail
            </div>
            <div
              style={{ width: "65%", wordWrap: "break-word"}}>
              {props.email}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px"}}>
            
            <div
              style={{width: "100%" }}>
              Citizen ID / Passport no
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px"}}>
            
            <div
              style={{width: "100%" }}>
              {props.citizen_no}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px", ...(props.isHiddenPartialMenu && {display: "none"})}}>
            
            <div
              style={{ width: "35%"}}>
              สิทธิ
            </div>
            <div
              style={{ width: "65%"}}>
              {props.coverage}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px",...(props.isHiddenPartialMenu && {display: "none"})}}>
            
            <div
              style={{ width: "35%"}}>
              Payer
            </div>
            <div
              style={{ width: "65%"}}>
              {props.payer}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px",...(props.isHiddenPartialMenu && {display: "none"})}}>
            
            <div
              style={{ width: "35%"}}>
              ศาสนา
            </div>
            <div
              style={{ width: "65%"}}>
              {props.religion}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px",...(props.isHiddenPartialMenu && {display: "none"})}}>
            
            <div
              style={{ width: "35%"}}>
              จุดสังเกต
            </div>
            <div
              style={{ width: "65%"}}>
              {props.remark}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px",...(props.isHiddenPartialMenu && {display: "none"})}}>
            
            <div
              style={{ width: "35%"}}>
              Checkout
            </div>
            <div
              style={{ width: "65%"}}>
              {props.checkout}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px",...(props.isHiddenAssessmentMenu && {display: "none"})}}>
            Isolate Precaution
          </div>
          <div
            style={{display: "flex", width : "95%", margin: "10px 0px",...(props.isHiddenAssessmentMenu && {display: "none"})}}>
            {props.isolatePrecaution}
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px",...(props.isHiddenAssessmentMenu && {display: "none"})}}>
            Fall Risk Assessment
          </div>
          <div
            style={{display: "flex", width : "95%", margin: "10px 0px",...(props.isHiddenAssessmentMenu && {display: "none"})}}>
            {}
            <Input
              readOnly={true}
              size="mini"
              style={{ width: "100%" }}
              value={props.fallRiskAssessment || "-"}>
            </Input>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px", ...(props.isHiddenPartialMenu && {display: "none"})}}>
            
            <div
              style={{ width: "35%"}}>
              หมายเหตุ
            </div>
            <div
              style={{ width: "65%"}}>
              {props.note}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px",...(props.isHiddenAssessmentMenu && {display: "none"})}}>
            สูบบุหรี่
          </div>
          <div
            style={{display: "flex", width : "95%", margin: "10px 0px",...(props.isHiddenAssessmentMenu && {display: "none"})}}>
            
            <Input
              readOnly={true}
              size="mini"
              style={{ width: "100%" }}
              value={props.tobacco || "-"}>
            </Input>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px",...(props.isHiddenAssessmentMenu && {display: "none"})}}>
            ดื่มสุรา
          </div>
          <div
            style={{display: "flex", width : "95%", margin: "10px 0px",...(props.isHiddenAssessmentMenu && {display: "none"})}}>
            
            <Input
              readOnly={true}
              size="mini"
              style={{ width: "100%" }}
              value={props.alcohol || "-"}>
            </Input>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px",...(props.isHiddenAssessmentMenu && {display: "none"})}}>
            เสี่ยงต่อการติดเชื้อ
          </div>
          <div
            style={{display: "flex", width : "95%", margin: "10px 0px",...(props.isHiddenAssessmentMenu && {display: "none"})}}>
            
            <Input
              readOnly={true}
              size="mini"
              style={{ width: "100%" }}
              value={props.mdrTriage || "-"}>
            </Input>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px",...(props.isHiddenAssessmentMenu && {display: "none"})}}>
            ประวัติการใช้ สารเสพย์ติด
          </div>
          <div
            style={{display: "flex", width : "95%", margin: "10px 0px",...(props.isHiddenAssessmentMenu && {display: "none"})}}>
            
            <Input
              readOnly={true}
              size="mini"
              style={{ width: "100%" }}
              value={props.narcotic || "-"}>
            </Input>
          </div>
          <div>
            
            <div>
              ภาษาหลักที่ใช้ในการติดต่อ
            </div>
            <div>
              {props.patientLanguage}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px"}}>
            
            <div>
              {props.telemed}
            </div>
            <div
              style={{ margin: "0px 3px" }}>
              {props.ButtonSuspending}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px", ...(props.hideTelemed && {display: "none"})}}>
            
            <div
              style={{ marginLeft: "35%" }}>
              {props.btnBindId}
            </div>
          </div>
        </div>
        <div
          style={{padding:"5px", ...(props.isHiddenPartialMenu && {display: "none"})}}>
          
          <Button
            color="blue"
            onClick={props.goToEclinical}
            style={{width: "100%", margin: "10px 0px",...(!props.ENABLE_ECLINICAL&& {display: "none"})}}>
            E-clinical
          </Button>
          <div
            style={{position :"relative", display: "none"}}>
            
            <TextArea
              style={{ width: "100%", height: "100%" }}
              value={props?.selectedADR || ""}>
            </TextArea>
            <Label
              onClick={props?.OnADR}
              style={{position: "absolute",right:"3px",bottom:"9px"}}>
              ADR
            </Label>
          </div>
          <div
            style={{position :"relative"}}>
            
            <TextArea
              style={{ width: "100%", height: "100%" }}
              value={props?.underlyingDisease || ""}>
            </TextArea>
            <Label
              onClick={ props?.OnUnderlyingDisease}
              style={{position: "absolute",right:"3px",bottom:"9px"}}>
              U/D
            </Label>
          </div>
          <div
            style={{position :"relative"}}>
            
            <TextArea
              style={{ width: "100%", height: "100%" }}
              value={props?.labResult || ""}>
            </TextArea>
            <Label
              onClick={ props?.OnLab}
              style={{position: "absolute",right:"3px",bottom:"9px"}}>
              Lab
            </Label>
          </div>
          <div
            style={{position :"relative"}}>
            
            <TextArea
              style={{ width: "100%", height: "100%" }}
              value={props?.vitalSign || ""}>
            </TextArea>
            <Label
              onClick={ props?.OnVitalSign}
              style={{position: "absolute",right:"3px",bottom:"9px"}}>
              V/S
            </Label>
          </div>
          <div
            style={{"display":"grid","position":"relative","gridTemplateRows":"repeat(5, 1fr)","gridTemplateColumns":"1fr 2fr", "paddingLeft":"10px"}}>
            
            <div>
              น้ำหนัก
            </div>
            <div>
              {props?.weigth}
            </div>
            <div>
              ส่วนสูง
            </div>
            <div>
              {props?.height}
            </div>
          </div>
        </div>
        <div>
          
          <div>
            {props.modalDrug}
          </div>
          <div>
            {props.modalFood}
          </div>
          <div>
            {props.modalOther}
          </div>
          <div>
            {props.modalKDA}
          </div>
          <div>
            {props.modalKFA}
          </div>
          <div>
            {props.modalKOA}
          </div>
        </div>
      </div>
    )
}


export default CardPatientPanelUX

export const screenPropsDefault = {"hn":"1234","patientImage":"https://www.gannett-cdn.com/presto/2022/02/27/USAT/1846e15e-f386-491c-9b5a-e6f9f6ace1e1-AP_Russia_Space_Putin.jpg?crop=3884,2913,x0,y0&quality=50&width=640","patientName":"วลาดิเมีย ปูติน","profileImage":"[Profile Image]","telemed":"[O]"}

/* Date Time : Wed Jan 29 2025 11:28:03 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\", display: \"flex\", flexDirection: \"column\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.profileImage"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"10px\", height: \"250px\", width: \"auto\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.statusAndAction"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 10px 0px 10px\",marginTop:\"10px\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding:\"5px\", ...(props.isHiddenPartialMenu && {display: \"none\"})}"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{position :\"relative\", display: \"none\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "TextArea",
      "parent": 72,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props?.selectedADR || \"\""
        }
      },
      "seq": 73,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Label",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "ADR"
        },
        "onClick": {
          "type": "code",
          "value": "props?.OnADR"
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\",right:\"3px\",bottom:\"9px\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{position :\"relative\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "TextArea",
      "parent": 75,
      "props": {
        "onBlur": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props?.underlyingDisease || \"\""
        }
      },
      "seq": 76,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Label",
      "parent": 75,
      "props": {
        "children": {
          "type": "value",
          "value": "U/D"
        },
        "onClick": {
          "type": "code",
          "value": " props?.OnUnderlyingDisease"
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\",right:\"3px\",bottom:\"9px\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{position :\"relative\"}"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{position :\"relative\"}"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "TextArea",
      "parent": 79,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props?.labResult || \"\""
        }
      },
      "seq": 81,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "TextArea",
      "parent": 80,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props?.vitalSign || \"\""
        }
      },
      "seq": 82,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "Label",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab"
        },
        "onClick": {
          "type": "code",
          "value": " props?.OnLab"
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\",right:\"3px\",bottom:\"9px\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "Label",
      "parent": 80,
      "props": {
        "children": {
          "type": "value",
          "value": "V/S"
        },
        "onClick": {
          "type": "code",
          "value": " props?.OnVitalSign"
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\",right:\"3px\",bottom:\"9px\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(5, 1fr)\",\"gridTemplateColumns\":\"1fr 2fr\", \"paddingLeft\":\"10px\"}"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "น้ำหนัก"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.weigth"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่วนสูง"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.height"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "Button",
      "parent": 214,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "icon": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPatientPanel-Button-CreateAppointment"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.goToCreateAppointment"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"grid\",alignItems: \"center\", marginTop:\"-50px\",float:'right'}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 99,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.adrList ?  \"flex\" : \"none\" ,justifyContent: \"row\", alignItems: \"center\", marginLeft:\"40px\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 100,
      "props": {
        "children": {
          "type": "code",
          "value": "props.otherImage"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"2px\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 100,
      "props": {
        "children": {
          "type": "code",
          "value": "props.foodImage"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"2px\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 100,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugImage"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"2px\"}"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 219,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 104,
      "props": {
        "children": {
          "type": "code",
          "value": "props.modalDrug"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 104,
      "props": {
        "children": {
          "type": "code",
          "value": "props.modalFood"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "div",
      "parent": 104,
      "props": {
        "children": {
          "type": "code",
          "value": "props.modalOther"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\" , position:\"relative\", \"paddingLeft\":\"10px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\"}"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "div",
      "parent": 118,
      "props": {
        "children": {
          "type": "code",
          "value": "props.hnLable"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\"}"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "div",
      "parent": 118,
      "props": {
        "children": {
          "type": "code",
          "value": "props.hn"
        },
        "style": {
          "type": "code",
          "value": "{width: \"65%\"}"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 121,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"15%\"}"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "div",
      "parent": 121,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientName"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\"}"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": "เพศ"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\", display: \"flex\", alignItems: \"center\", }"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 124,
      "props": {
        "children": {
          "type": "code",
          "value": "props.gender"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"32%\", display: \"flex\", alignItems: \"center\",}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "div",
      "parent": 127,
      "props": {
        "children": {
          "type": "value",
          "value": "อายุ"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\"}"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "div",
      "parent": 127,
      "props": {
        "children": {
          "type": "code",
          "value": "props.age"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\"}"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 130,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเกิด"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\"}"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 130,
      "props": {
        "children": {
          "type": "code",
          "value": "props.birthdate"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\"}"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "div",
      "parent": 133,
      "props": {
        "children": {
          "type": "value",
          "value": "เบอร์โทร"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\"}"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "div",
      "parent": 133,
      "props": {
        "children": {
          "type": "code",
          "value": "props.tel_mobile"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\"}"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\", }"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": "E-mail"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\"}"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "code",
          "value": "props.email"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\", wordWrap: \"break-word\"}"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\", ...(props.isHiddenPartialMenu && {display: \"none\"})}"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "div",
      "parent": 139,
      "props": {
        "children": {
          "type": "value",
          "value": "สิทธิ"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\"}"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 139,
      "props": {
        "children": {
          "type": "code",
          "value": "props.coverage"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\"}"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": null,
      "id": 144,
      "name": "div",
      "parent": 142,
      "props": {
        "children": {
          "type": "value",
          "value": "Citizen ID / Passport no"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\" }"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "div",
      "parent": 143,
      "props": {
        "children": {
          "type": "code",
          "value": "props.citizen_no"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\" }"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 146,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\",...(props.isHiddenPartialMenu && {display: \"none\"})}"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\",...(props.isHiddenPartialMenu && {display: \"none\"})}"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\",...(props.isHiddenPartialMenu && {display: \"none\"})}"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\",...(props.isHiddenPartialMenu && {display: \"none\"})}"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\", ...(props.isHiddenPartialMenu && {display: \"none\"})}"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": null,
      "id": 151,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "div",
      "parent": 146,
      "props": {
        "children": {
          "type": "value",
          "value": "Payer"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\"}"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "div",
      "parent": 146,
      "props": {
        "children": {
          "type": "code",
          "value": "props.payer"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\"}"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": null,
      "id": 158,
      "name": "div",
      "parent": 147,
      "props": {
        "children": {
          "type": "value",
          "value": "ศาสนา"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\"}"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "div",
      "parent": 147,
      "props": {
        "children": {
          "type": "code",
          "value": "props.religion"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\"}"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "div",
      "parent": 148,
      "props": {
        "children": {
          "type": "value",
          "value": "Checkout"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\"}"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 161,
      "name": "div",
      "parent": 148,
      "props": {
        "children": {
          "type": "code",
          "value": "props.checkout"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\"}"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 162,
      "name": "div",
      "parent": 149,
      "props": {
        "children": {
          "type": "value",
          "value": "จุดสังเกต"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\"}"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": null,
      "id": 163,
      "name": "div",
      "parent": 149,
      "props": {
        "children": {
          "type": "code",
          "value": "props.remark"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\"}"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "div",
      "parent": 150,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเหตุ"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\"}"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": null,
      "id": 165,
      "name": "div",
      "parent": 150,
      "props": {
        "children": {
          "type": "code",
          "value": "props.note"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\"}"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "div",
      "parent": 151,
      "props": {
        "children": {
          "type": "code",
          "value": "props.telemed"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 168,
      "name": "div",
      "parent": 104,
      "props": {
        "children": {
          "type": "code",
          "value": "props.modalKDA"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "div",
      "parent": 104,
      "props": {
        "children": {
          "type": "code",
          "value": "props.modalKFA"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "div",
      "parent": 104,
      "props": {
        "children": {
          "type": "code",
          "value": "props.modalKOA"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "div",
      "parent": 151,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSuspending"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 3px\" }"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 172,
      "name": "Button",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "E-clinical"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.goToEclinical"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", margin: \"10px 0px\",...(!props.ENABLE_ECLINICAL&& {display: \"none\"})}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "div",
      "parent": 124,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pregnancyImage"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\", alignItems: \"center\",  ...(!props.isShowPregnancy && { display: \"none\"})}"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 174,
      "name": "Button",
      "parent": 215,
      "props": {
        "color": {
          "type": "value",
          "value": "pink"
        },
        "disabled": {
          "type": "code",
          "value": "!props.showDentalHistory"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPatientPanel-Button-DentalHistory"
        },
        "onClick": {
          "type": "code",
          "value": "props.goToDentalHistory"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "div",
      "parent": 174,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", gap:\"20px\",alignItems:\"center\"}"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "img",
      "parent": 175,
      "props": {
        "src": {
          "type": "value",
          "value": "/static/images/tooth-icon.png"
        },
        "style": {
          "type": "code",
          "value": "{width: \"10px\", marginRight: \"4px\",marginLeft:\"2px\"}"
        }
      },
      "seq": 176,
      "void": true
    },
    {
      "from": null,
      "id": 177,
      "name": "label",
      "parent": 175,
      "props": {
        "children": {
          "type": "value",
          "value": "Dental History"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": null,
      "id": 178,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": null,
      "id": 179,
      "name": "div",
      "parent": 178,
      "props": {
        "children": {
          "type": "value",
          "value": "ภาษาหลักที่ใช้ในการติดต่อ"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": null,
      "id": 180,
      "name": "div",
      "parent": 178,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientLanguage"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": null,
      "id": 181,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonToggleTabMenu"
        },
        "style": {
          "type": "code",
          "value": "{\"height\":\"30px\",\"marginBottom\":\"-24px\",\"position\":\"relative\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 182,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\", ...(props.hideTelemed && {display: \"none\"})}"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": null,
      "id": 183,
      "name": "div",
      "parent": 182,
      "props": {
        "children": {
          "type": "code",
          "value": "props.btnBindId"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"35%\" }"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": null,
      "id": 184,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": "Isolate Precaution"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\",...(props.isHiddenAssessmentMenu && {display: \"none\"})}"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": null,
      "id": 185,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "code",
          "value": "props.isolatePrecaution"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"95%\", margin: \"10px 0px\",...(props.isHiddenAssessmentMenu && {display: \"none\"})}"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 186,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": "Fall Risk Assessment"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\",...(props.isHiddenAssessmentMenu && {display: \"none\"})}"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": null,
      "id": 187,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"95%\", margin: \"10px 0px\",...(props.isHiddenAssessmentMenu && {display: \"none\"})}"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": "สูบบุหรี่"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\",...(props.isHiddenAssessmentMenu && {display: \"none\"})}"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": "ดื่มสุรา"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\",...(props.isHiddenAssessmentMenu && {display: \"none\"})}"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": null,
      "id": 192,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": "เสี่ยงต่อการติดเชื้อ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\",...(props.isHiddenAssessmentMenu && {display: \"none\"})}"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": null,
      "id": 193,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการใช้ สารเสพย์ติด"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\",...(props.isHiddenAssessmentMenu && {display: \"none\"})}"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": null,
      "id": 202,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"95%\", margin: \"10px 0px\",...(props.isHiddenAssessmentMenu && {display: \"none\"})}"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": null,
      "id": 203,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"95%\", margin: \"10px 0px\",...(props.isHiddenAssessmentMenu && {display: \"none\"})}"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": null,
      "id": 204,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"95%\", margin: \"10px 0px\",...(props.isHiddenAssessmentMenu && {display: \"none\"})}"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": null,
      "id": 205,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"95%\", margin: \"10px 0px\",...(props.isHiddenAssessmentMenu && {display: \"none\"})}"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 206,
      "name": "Input",
      "parent": 187,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "readonly": {
          "type": "code",
          "value": ""
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.fallRiskAssessment || \"-\""
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 207,
      "name": "Input",
      "parent": 202,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "readonly": {
          "type": "code",
          "value": ""
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.tobacco || \"-\""
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 208,
      "name": "Input",
      "parent": 203,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "readonly": {
          "type": "code",
          "value": ""
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.alcohol || \"-\""
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 209,
      "name": "Input",
      "parent": 204,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "readonly": {
          "type": "code",
          "value": ""
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.mdrTriage || \"-\""
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 210,
      "name": "Input",
      "parent": 205,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "readonly": {
          "type": "code",
          "value": ""
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.narcotic || \"-\""
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": null,
      "id": 211,
      "name": "div",
      "parent": 121,
      "props": {
        "children": {
          "type": "code",
          "value": "props.oldNameIcon"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\"}"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 212,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.totalBalancePrice"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", paddingTop: \"35px\", ...(!props.hasDebt && { display: \"none\" }) }"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 213,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.actionButton"
        },
        "style": {
          "type": "code",
          "value": "{...(props.actionButton&&{padding: \"10px\",marginTop:\"10px\"}), ...(props.hideTelemed && { display: \"none\"})}"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": null,
      "id": 214,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"0.75rem\", padding: \"0px 13px 0px 35px\", minWidth: \"8.75rem\", display: props.showCreateAppointment ? \"grid\":\"none\"}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 215,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showDentalHistory ? \"\":\"none\",padding: \"0px 10px\", marginTop:\"10px\"}"
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 216,
      "name": "Icon",
      "parent": 218,
      "props": {
        "name": {
          "type": "value",
          "value": "calendar alternate"
        }
      },
      "seq": 217,
      "void": false
    },
    {
      "from": null,
      "id": 217,
      "name": "div",
      "parent": 218,
      "props": {
        "children": {
          "type": "value",
          "value": "ทำนัดหมาย"
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": null,
      "id": 218,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", gap:\"10px\",alignItems:\"center\"}"
        }
      },
      "seq": 216,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 100,
  "isMounted": false,
  "memo": false,
  "name": "CardPatientPanelUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "hn": "1234",
    "patientImage": "https://www.gannett-cdn.com/presto/2022/02/27/USAT/1846e15e-f386-491c-9b5a-e6f9f6ace1e1-AP_Russia_Space_Putin.jpg?crop=3884,2913,x0,y0&quality=50&width=640",
    "patientName": "วลาดิเมีย ปูติน",
    "profileImage": "[Profile Image]",
    "telemed": "[O]"
  },
  "width": 15
}

*********************************************************************************** */
