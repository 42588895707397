import { useState, useEffect, useMemo, SyntheticEvent, useRef, useCallback } from "react";
import { Image, Icon, Label, Button, Modal, Segment, Form, TextArea } from "semantic-ui-react";
import { Dialog } from "@mui/material";

import moment from "moment";

// Framework
import { Table } from "react-lib/frameworks/Table";

// UX
import CardPatientPanelUX from "./CardPatientPanelUX";
import CardVitalSign from "react-lib/apps/HISV3/PTM/CardVitalSign";
import ModAllergyUX from "react-lib/apps/HISV3/PTM/ModAllergyUX";
import CardUnderlyingDiseaseUX from "react-lib/apps/HISV3/PTM/CardUnderlyingDiseaseUX";
import CardAllergyActionLog from "react-lib/apps/HISV3/TPD/CardAllergyActionLog";
import CardAdverseReactionNote from "react-lib/apps/HISV3/TPD/CardAdverseReactionNote";
import CardSuspending from "./CardSuspending";
import WebcamProfile from "./WebcamProfile";
import ButtonBindId from "./ButtonBindId";
import PatientStatusActionButton from "./PatientStatusActionButton";
import CardSubAllergyHistory, {
  getDrugStatus,
  getFoodNOtherStatus,
} from "../TPD/CardSubAllergyHistory";
import { ADR_TYPES } from "react-lib/apps/HISV3/TPD/sequence/Allergy";

// Common
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import { alignCenter } from "react-lib/apps/common/PureReactTable";
import CardPatientOldName from "react-lib/apps/common/CardPatientOldName";

// Utils
import { formatDatetime, adToBe } from "react-lib/utils/dateUtils";
import {
  precautionOptions,
  fallRiskOptions,
  narcoticOptions,
  mdrOptions,
} from "react-lib/apps/HISV3/PTM/CardAssessmentBundle";
import {
  tobaccoOptions,
  alcoholOptions,
} from "react-lib/apps/HISV3/ADM/sequence/PreAssessment";

// Styles
import "./CardPatientPanel.scss";
import CONFIG from "config/config";
import { styles as MedFeeStyles } from "react-lib/apps/HISV3/BIL/CardMedicalFee";
import { useIntl } from "react-intl";

const CARD_ALLERGY = "CardAllergy";
const CARD_PATIENT_PANEL = "CardPatientPanel";

const SEMANTIC_COLOR = {
  blue: "rgba(33, 133, 208, 1)",
  yellow: "rgb(251, 189, 8)",
  orange: "rgba(242, 113, 28)",
  olive: "rgba(181, 204, 24)",
};

const IMAGES = {
  // pregnancyStatus
  pregnancyPeriodNA: "/static/images/pregnancy/pregnancyPeriodNA.png",
  pregnancyPeriod1: "/static/images/pregnancy/pregnancyPeriod1.png",
  pregnancyPeriod2: "/static/images/pregnancy/pregnancyPeriod2.png",
  pregnancyPeriod3: "/static/images/pregnancy/pregnancyPeriod3.png",
};

const CardPatientPanel = (props: any) => {
  const intl = useIntl();
  const [openWebCam, setOpenWebCam] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [openModSelectedADR, setOpenModSelectedADR] = useState<boolean>(false);
  const [openModSelectedUD, setOpenModSelectedUD] = useState<boolean>(false);
  const [openModSelectedLAB, setOpenModSelectedLAB] = useState<boolean>(false);
  const [openModSelectedVS, setOpenModSelectedVS] = useState<boolean>(false);
  const [openModADRNote, setOpenModADRNote] = useState<boolean>(false);
  const [actionLogId, setActionLogId] = useState<number | null>(null);

  const [openModNote, setOpenModNote] = useState<boolean>(false);
  const [adrClick, setAdrClick] = useState<any>(null);
  const [actionButton, setActionButton] = useState<string>("");

  const handleCloseModSelectedADR = () => setOpenModSelectedADR(false);
  const handleCloseModSelectedUD = () => setOpenModSelectedUD(false);
  const handleCloseModSelectedLAB = () => setOpenModSelectedLAB(false);
  const handleCloseModSelectedVS = () => setOpenModSelectedVS(false);
  const handleCloseModADRNote = () => setOpenModADRNote(false);

  const [openEditProblemList, setOpenEditProblemList] = useState(false);
  const [dataProblemList, setDataProblemList] = useState<any>();
  const [textEdit, setTextEdit] = useState("");
  const [openPregnancyLog, setOpenPregnancyLog] = useState(false);
  const [modAllergyHistory, setModAllergyHistory] = useState<any>("");

  const [openCardPatientOldName, setOpenCardPatientOldName] = useState<boolean>(false);

  const newWindowRef = useRef<any>(null);

  useEffect(() => {
    // form: is check ViewRegistration || ViewEncounter
    props.runSequence({
      sequence: "CreateUpdateEncounter",
      card: "CreateUpdateEncounter",
      form: props.form,
      restart: true,
      isNoneFilterDate: true,
    });
  }, [
    // ViewRegistration
    props.selectedPatient?.encounter_id,
    props.selectedPatient?.encounter_no,
    props.selectedPatient?.hn,
    // ViewEncounter
    props.selectedEncounter?.patient_id,
  ]);

  // * Get allergy เพื่อแสดง icon หน้า Patient panel
  useEffect(() => {
    if (props.selectedPatient?.id) {
      props.runSequence({
        sequence: "Allergy",
        form: props.form,
        restart: true,
        noEmr: true,
      });
    }

    return () => {
      props.runSequence({ sequence: "Allergy", clear: true });
    };
  }, [props.selectedPatient?.id]);

  // * Get allergy เพื่อแสดง icon หน้า Patient panel
  useEffect(() => {
    if (!Number.isInteger(props.selectedPatient?.id)) return;
    props.runSequence({ sequence: "Assessment", restart: true });
  }, [props.selectedPatient?.id]);

  useEffect(() => {
    props.onEvent({ message: "HandleDidMountEncounter", params: {} });

    return () => {
      props.onEvent({
        message: "HandleUnmountEncounter",
        params: {
          onUnmounted: () => {
            const isAppointmentTabActive =
              document.querySelector("#tab-Appointment")?.className === "active";

            if (isAppointmentTabActive) {
              props.onEvent({ message: "HandleGoBackAppointment", params: {} });
            }else {
              props.setProp("goBackAppointmentState", null);
            }
          },
        },
      });
    };
  }, []);

  // console.log("Allergy -> PatientPanel", props?.AllergySequence?.adrList);
  const handleSaveNewImage = async (imgSrc: any) => {
    // console.log(imgSrc);
    // imgSrc is base64 encoded string
    console.log(imgSrc);
    let data = {
      image: imgSrc,
      image_type: "P",
    };
    props.setProp("SetPatientSequence.patient.profile_image", data);
  };

  const handleShowInactive = (e: any, { checked }: any) => {
    props.setProp("AllergySequence.showInactive", checked);

    props.runSequence({
      sequence: "Allergy",
      action: "inactive",
      checked: checked,
    });
  };

  const handleCloseCardPatientOldName = () => {
    setOpenCardPatientOldName(false);
  };

  const onOpenModPatientOldName = (e: any, patient: any) => {
    e.preventDefault();
    e.stopPropagation();
    props.onEvent({
      message: "HandleGetPatientOldName",
      params: {
        action: "oldname_list",
        patient: props.selectedPatient,
      },
    });
    setOpenCardPatientOldName(true);
  };

  const filterADR = useCallback((adrList: any[], type: any) => {
    return adrList.filter((item) => item.type_name_name === type);
  }, []);

  // #const adrIcon = useMemo(() => {
  //   if (props.patientADR?.items) {
  //     if (props.patientADR?.items?.length === 0) {
  //       return null;
  //     } else if (
  //       props.patientADR?.items?.length === 1 &&
  //       props.patientADR?.items?.[0].status === "NO_REACTION"
  //     ) {
  //       return <Label> NKA </Label>;
  //     } else {
  //       return <Label color="red"> ADR </Label>;
  //     }
  //   }
  // }, [props.patientADR]);

  const ardList = useMemo(
    () =>
      props.patientADR?.items?.map((item: any, idx: any) => {
        return {
          ...item,
        };
      }),
    [props.patientADR]
  );

  const pregnancyLog = useMemo(
    () =>
      props.CreateUpdateEncounterSequence?.pregnancyLog?.map((item: any, idx: any) => {
        return {
          ...item,
          tracked_data_detail: (
            <>
              {item?.tracked_data?.pregnancy_status === 1
                ? "ไม่ทราบ"
                : item?.tracked_data?.pregnancy_status === 2
                ? "ไม่ตั้งครรภ์"
                : item?.tracked_data?.pregnancy_status === 3
                ? `กำลังตั้งครรภ์ ${
                    props.CreateUpdateEncounterSequence?.pregnancyPeriodOptions?.find(
                      (opt: any) => opt.value === item?.tracked_data?.pregnancy_period
                    )?.text || ""
                  }`
                : ""}
            </>
          ),
        };
      }),
    [props.CreateUpdateEncounterSequence?.pregnancyLog]
  );

  const pregnancyStatus = useMemo(() => {
    if (
      props.CreateUpdateEncounterSequence?.pregnancy_status === 3 &&
      props.selectedPatient?.gender_name === "Female" &&
      parseInt(props.selectedPatient?.age) >= 15 &&
      parseInt(props.selectedPatient?.age) <= 50
    ) {
      return true;
    } else {
      return false;
    }
  }, [props.CreateUpdateEncounterSequence?.pregnancy_status]);

  const pregnancyImage = useMemo(() => {
    if (props.CreateUpdateEncounterSequence?.pregnancy_period === 0) {
      return (
        <Image
          width={50}
          src={IMAGES.pregnancyPeriodNA}
          onClick={() => {
            setOpenPregnancyLog(true);
            props.runSequence({
              sequence: "CreateUpdateEncounter",
              action: "getLogPregnancy",
            });
          }}
        ></Image>
      );
    } else if (props.CreateUpdateEncounterSequence?.pregnancy_period === 1) {
      return (
        <Image
          width={50}
          src={IMAGES.pregnancyPeriod1}
          onClick={() => {
            setOpenPregnancyLog(true);
            props.runSequence({
              sequence: "CreateUpdateEncounter",
              action: "getLogPregnancy",
            });
          }}
        ></Image>
      );
    } else if (props.CreateUpdateEncounterSequence?.pregnancy_period === 2) {
      return (
        <Image
          width={50}
          src={IMAGES.pregnancyPeriod2}
          onClick={() => {
            setOpenPregnancyLog(true);
            props.runSequence({
              sequence: "CreateUpdateEncounter",
              action: "getLogPregnancy",
            });
          }}
        ></Image>
      );
    } else {
      return (
        <Image
          width={50}
          src={IMAGES.pregnancyPeriod3}
          onClick={() => {
            setOpenPregnancyLog(true);
            props.runSequence({
              sequence: "CreateUpdateEncounter",
              action: "getLogPregnancy",
            });
          }}
        ></Image>
      );
    }
  }, [props.CreateUpdateEncounterSequence?.pregnancy_period]);

  //PatienPanel ADR
  const patientADRSort = props.patientADR?.items?.sort((a: any, b: any) => (a.no < b.no ? 1 : -1));
  const ADR = patientADRSort
    ?.map((item: any) =>
      item.name ? `-${item.adr_short_info}` : item.note ? `-${item.note}` : null
    )
    .join("\n");

  const openNote = (item: any) => {
    setAdrClick(item);
    setOpenModNote(true);
  };

  const drugLabInteraction = useMemo(() => {
    return (props.LabResult || [])
      .map((item: any) => {
        let date = adToBe(moment(item.date).format("DD/MM/YYYY"));
        return item.name ? `-${item.name} ${item.value} ${item.unit} [${date}]` : null;
      })
      ?.join("\n");
  }, [props.LabResult]);

  const copySelectedPatient = useMemo(() => {
    return { ...props.selectedPatient };
  }, [props.selectedPatient]);

  const labData = useMemo(() => {
    return (props.LabResult || []).map((item: any) => {
      let date = adToBe(moment(item.date).format("DD/MM/YYYY"));
      return {
        code: alignCenter(item.code),
        name: alignCenter(item.name),
        value: alignCenter(item.value),
        unit: alignCenter(item.unit),
        result_date: alignCenter(`${date} [${moment(item.date).format("HH:mm")}]`),
      };
    });
  }, [props.LabResult]);

  // Memo
  const groupADR = useMemo(() => {
    const adrList: any[] = props.AllergySequence?.adrList || [];

    return {
      drug: filterADR(adrList, ADR_TYPES.DRUG),
      food: filterADR(adrList, ADR_TYPES.FOOD),
      other: filterADR(adrList, ADR_TYPES.OTHER),
    };
  }, [props.AllergySequence?.adrList]);

  const statusLabel = useMemo(() => {
    return {
      drug: getDrugStatus(groupADR.drug.filter((item) => !item.is_inactive)),
      food: getFoodNOtherStatus(groupADR.food, "food"),
      other: getFoodNOtherStatus(groupADR.other, "other"),
    };
  }, [groupADR]);

  const allergyListMod = useMemo(
    () =>
      patientADRSort?.map((item: any, index: number) => {
        return (
          <div style={{ marginTop: "2px" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                position: "relative",
                gridTemplateRows: "repeat(2, 1fr)",
                gridTemplateColumns: "repeat(50, 1fr)",
              }}
            >
              <div style={{ gridRow: "1/2", gridColumn: "1/2" }}>{index + 1}</div>
              <div style={{ gridRow: "1/2", gridColumn: "3/5" }}>
                <Label
                  style={{
                    color: "white",
                    backgroundColor:
                      item.status === "NOTED"
                        ? SEMANTIC_COLOR.yellow
                        : item.status === "LOCKED"
                        ? SEMANTIC_COLOR.orange
                        : item.status === "PAUSED"
                        ? SEMANTIC_COLOR.olive
                        : "",
                  }}
                >
                  {item.status}
                </Label>
              </div>
              <div style={{ gridRow: "1/2", gridColumn: "10/15" }}>{item.name}</div>
              <div style={{ gridRow: "1/2", gridColumn: "17/25" }}>{item.note}</div>
              <div style={{ gridRow: "1/2", gridColumn: "43/50" }}>
                <Button
                  size="mini"
                  icon="edit"
                  color="yellow"
                  onClick={(event: SyntheticEvent) => {
                    props.runSequence({
                      sequence: "Allergy",
                      action: "selectedADR",
                      original: item,
                    });
                    setOpenModADRNote(true);
                  }}
                />

                <Button
                  size="mini"
                  icon={item.status === "LOCKED" ? "unlock" : "lock"}
                  color={item.status === "LOCKED" ? "olive" : "orange"}
                  onClick={(event: SyntheticEvent) => {
                    openNote(item);
                    setActionButton("toggleLock");
                  }}
                />

                <Button
                  size="mini"
                  icon="trash alternate"
                  color="red"
                  onClick={(event: SyntheticEvent) => {
                    openNote(item);
                    setActionButton("cancel");
                  }}
                />

                <Button
                  size="mini"
                  icon="history"
                  onClick={() => {
                    setActionLogId(item?.id);
                  }}
                />
              </div>
            </div>
          </div>
        );
      }),
    [props.patientADR]
  );

  const actionLogData = useMemo(
    () =>
      props.AllergySequence?.actionLog?.items?.map((item: any) => ({
        ...item,
        datetime: formatDatetime(item.datetime),
      })),
    [props.AllergySequence?.actionLog]
  );

  // NOTE
  const handleNoteConfirmed = () => {
    props.runSequence({
      sequence: "Allergy",
      action: "actionButton",
      card: CARD_ALLERGY,
      ...(actionButton === "toggleLock" && { toggleLock: true }),
      ...(actionButton === "cancel" && { cancel: true }),
      item: adrClick,
    });
    setOpenModNote(false);
  };

  const handleNoteDeny = () => {
    props.runSequence({
      sequence: "Allergy",
      action: "clearNote",
      card: CARD_ALLERGY,
    });

    setOpenModNote(false);
  };

  //PatienPanel UD
  const underlyingDiseaseList = props?.problemListDPO?.items?.map((item: any, index: number) => {
    return (
      <div style={{ borderTop: index === 0 ? "" : "2px solid #ccc" }}>
        <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
          <div style={{ textDecoration: item.is_active ? "" : "line-through" }}>
            {`${index + 1}. ${item.message}`}
          </div>
          <div style={{ flex: "1" }} />
          <div style={{ margin: "0px 10px" }}>
            <Button
              size="mini"
              icon="edit"
              color="yellow"
              disabled={!item.is_active}
              onClick={() => {
                setOpenEditProblemList(true);
                setDataProblemList(item);
                setTextEdit(item.message);
              }}
            />
          </div>
          <div>
            <Button
              size="mini"
              icon={item.is_active ? "ban" : "check"}
              color={item.is_active ? "red" : "green"}
              onClick={() => {
                props.onEvent({
                  message: "HandleAddMessageProblemDPO",
                  params: {
                    action: "editProblemList",
                    pk: item.pk,
                    patient: item.patient,
                    is_active: !item.is_active,
                    message: item.message,
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  });

  const underlyingDisease = props?.problemListDPO?.items
    ?.filter((item: any) => item.is_active === true)
    ?.map((item: any) => item.message)
    .join(" , ");

  //PatienPanel VS
  const filterVitalSign = props?.vitalSignsInfo?.items?.filter(
    (item: any, index: number) =>
      item.vitalsign_type_code !== "Weight" && item.vitalsign_type_code !== "Height" && index !== 0
  );
  const sortVitalSign = filterVitalSign?.sort((a: any, b: any) =>
    a.display_seq > b.display_seq ? 1 : -1
  );
  const vitalSignList = sortVitalSign
    ?.map((item: any) => `${item.name}(${item.unit}) : ${item.result}`)
    .join("\n");

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleOnChangeNoteADR = (key: string) => (e: any, v: any) => {
    console.log("Key", key);
    props.setProp("AllergySequence", {
      ...props.AllergySequence,
      sequenceIndex: "Edit",
      adrSelected: {
        [key]: v.value,
      },
    });
  };

  const handleCancelNewADRNote = () => {
    props.onEvent({
      message: "RunSequence",
      params: { sequence: "Allergy", action: "clear" },
    });
    // setOpenADRDetail(false);
  };

  const handleSaveNewADRNote = () => {
    if (props?.AllergySequence?.adrSelected) {
      props.onEvent({
        message: "RunSequence",
        params: { sequence: "Allergy", action: "save" },
      });
    }
  };

  const handleChangeMessageProblemDPO = (e: any, v: any) => {
    props.setProp("messageProblemDPO", v.value);
  };

  const handleGoToDentalHistory = () => {
    const isDoctor = props.django?.user?.role_types?.includes("DOCTOR");
    const pathName = `${CONFIG.SHOW_DENTAL_HISTORY}/?app=${isDoctor ? "MainDPO" : "MainPTM"}`; // "https://issara-platform.web.app/?app=HIS"
    const url = `${pathName}&HN=${props.selectedPatient?.hn}&history=true`;

    newWindowRef.current?.close();

    newWindowRef.current = globalThis.open(url, "_blank");
  };

  const handleOpenModAllergyHistory = (type: any) => () => {
    setModAllergyHistory(type);
  };

  const handleCloseModAllergyHistory = () => {
    setModAllergyHistory("");
  };

  const handleGoToCreateAppointment = () => {
    props.onEvent({
      message: "HandleGoToCreateAppointment",
      params: { tabPatientRef: props.tabPatientRef },
    });
  };

  const handleSetIsolatePrecaution = useMemo(() => {
    const precautionData = props.AssessmentSequence?.patientAssessment?.data?.precaution || "";
    // * precaution มี v0.1 = text, v0.2 value[]
    let isolatePrecaution: string[] | string =
      props.AssessmentSequence?.patientAssessment?.data?.precaution || "";

    if (Array.isArray(isolatePrecaution)) {
      const precautionValues = isolatePrecaution
        .map((value) => precautionOptions.find((option: any) => option.value === value)?.text)
        .filter(Boolean);

      isolatePrecaution = precautionValues.join(", ");
    }

    return (
      <Form>
        <TextArea
          value={isolatePrecaution}
          rows={Array.isArray(precautionData) ? precautionData.length : 1}
          readOnly={true}
          style={{ resize: "none", padding: "6px 5px" }}
        ></TextArea>
      </Form>
    );
  }, [props.AssessmentSequence?.patientAssessment?.data]);

  const handleSetFallRiskAssessment = useMemo(() => {
    const fallRiskData = props.AssessmentSequence?.fallRiskAssessment?.data || [];
    const selectFallRisk = fallRiskData.slice(-1)[0];
    // * fall risk มี v0.1 = fall_risk, v0.2 fall_riskV2
    const fallRiskValue = selectFallRisk?.fall_riskV2 || selectFallRisk?.fall_risk;

    return fallRiskOptions.find((data: any) => data.value === fallRiskValue)?.text;
  }, [props.AssessmentSequence?.fallRiskAssessment?.data]);

  const balancePriceLabel = useMemo(() => {
    const hasDebt = props.AssessmentSequence?.patientHasDebtView?.has_debt;
    const isLocked = props.AssessmentSequence?.encounterLocker?.__error_type__ === "BILLING_LOCKED";

    let label = "";

    if (isLocked) {
      label = "การเงินกำลังตรวจค่าใช้จ่าย";
    } else if (hasDebt) {
      label = "ผู้ป่วยมียอดค้างชำระ";
    }

    return {
      label,
      show: hasDebt || isLocked,
    };
  }, [props.AssessmentSequence?.encounterLocker, props.AssessmentSequence?.patientHasDebtView]);

  return (
    <div className="card-patient-panel">
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_PATIENT_PANEL}`, null);
        }}
        error={props.errorMessage?.[CARD_PATIENT_PANEL]}
        success={null}
        languageUX={props.languageUX}
      />

      <Modal open={openCardPatientOldName}>
        <CardPatientOldName
          hideCallBack={handleCloseCardPatientOldName}
          hn={props.selectedPatient?.hn || "-"}
          fullName={props.selectedPatient?.full_name || "-"}
          patientOldNameLoading={false}
          patientOldNameDataList={props.SetPatientSequence?.patientOldNameList || []}
          languageUX={props.languageUX}
        />
      </Modal>

      <Dialog onClose={handleClose} open={openDialog} fullWidth={true} maxWidth={"md"}>
        <Table
          data={ardList}
          headers={intl.formatMessage({
            id: "ชื่อ,ประเภท,อาการ,ความรุนแรง,ข้อความแจ้งเตือน,สถานะ,ผู้บันทึก,วันที่-เวลา",
          })}
          keys="name,type_name,severity_name,severity_name,note,status,edit_user,edited"
          showPagination={false}
          minRows="3"
        ></Table>
      </Dialog>
      <CardPatientPanelUX
        ENABLE_ECLINICAL={CONFIG.ENABLE_ECLINICAL}
        hideTelemed={CONFIG.HIDE_TELEMED}
        showDentalHistory={
          CONFIG.SHOW_DENTAL_HISTORY &&
          props.django?.user?.role_types?.find((role: string) =>
            ["DOCTOR", "REGISTERED_NURSE", "PHARMACIST"].includes(role)
          )
        }
        showCreateAppointment={!["การเงิน"].includes(props.divisionType || "")}
        goToEclinical={() => {}} // TODO: add link Eclinical
        goToCreateAppointment={handleGoToCreateAppointment}
        goToDentalHistory={handleGoToDentalHistory}
        patientImage={props.selectedPatient?.profile_image?.image}
        buttonToggleTabMenu={
          <ButtonToggleTabMenu
            hideTabMenu={props.hideTabMenu}
            setHideTabMenu={props.setHideTabMenu}
          />
        }
        hasDebt={balancePriceLabel.show}
        totalBalancePrice={
          <>
            {!!props.django?.user?.role_types?.find((role: string) =>
              ["DOCTOR", "REGISTERED_NURSE", "GENERAL"].includes(role)
            ) &&
              !!balancePriceLabel.label && (
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      ...MedFeeStyles.message,
                      backgroundColor: "#E4A210",
                      fontSize: "0.9rem",
                      borderRadius: "0px",
                      padding: "6px 5px 4px",
                      margin: "-0.25rem 0 -1.15rem",
                      textAlign: "center",
                    }}
                  >
                    {balancePriceLabel.label}
                  </div>
                </div>
              )}
          </>
        }
        profileImage={
          <div className="Img-container">
            <Image
              id="CardPatientPanel-Image-ProfileImage"
              src={
                props.selectedPatient?.profile_image != null
                  ? props.selectedPatient?.profile_image?.image
                  : "/static/images/person.png"
              }
              // size="small"
              // className="image"
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.src = "/static/images/person.png";
              }}
            />
            <div className="middle">
              <div className="InputUpload" onClick={() => setOpenWebCam(true)}>
                <Icon id="CardPatientPanel-Icon-UploadImage" className="camera" size="large" />
              </div>
            </div>
          </div>
        }
        hnLable={
          <div>
            <div>{`HN`}</div>
            {props.selectedEncounter?.number && <div>{`EN / AN`}</div>}
          </div>
        }
        hn={
          <div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 2rem" }}>
              <div>
                <div>{props.selectedPatient?.hn}</div>
                {props.selectedEncounter?.number && (
                  <div>
                    ({props.selectedEncounter?.full_number || props.selectedEncounter?.number})
                  </div>
                )}
              </div>
              {props.chatDetail && (
                <Icon.Group>
                  <Icon
                    name="facebook messenger"
                    style={{ fontSize: "1.7rem", color: "#1c9e2a" }}
                  />
                  <Icon
                    name="phone"
                    style={{
                      color: "white",
                      fontSize: ".8rem",
                      top: ".3rem",
                      left: ".3rem",
                      transform: "rotate(90deg)",
                      zIndex: 1,
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      width: "1.1rem",
                      height: "1.1rem",
                      borderRadius: "500rem",
                      backgroundColor: "#1c9e2a",
                      top: ".2rem",
                      left: ".2rem",
                    }}
                  ></div>
                </Icon.Group>
              )}
            </div>
          </div>
        }
        // check adrList
        adrList={props?.AllergySequence?.adrList}
        patientLanguage={props.selectedPatient?.patientLanguage}
        patientName={props.selectedPatient?.full_name}
        oldNameIcon={
          props.selectedPatient?.old_name && (
            <Icon
              name="history"
              style={{ fontSize: "1.0rem", color: "#2185d0" }}
              onClick={(event: SyntheticEvent) => {
                onOpenModPatientOldName(event, props.selectedPatient);
              }}
            />
          )
        }
        gender={props.selectedPatient?.gender_name}
        age={
          props.selectedPatient?.full_age
            ? props.selectedPatient?.full_age
            : props.selectedPatient?.age > 500
            ? ""
            : props.selectedPatient?.age
        }
        birthdate={
          copySelectedPatient?.birthdate !== "01/01/1970" ? copySelectedPatient?.birthdate : ""
        }
        email={copySelectedPatient?.present_address?.email}
        tel_mobile={copySelectedPatient?.present_address?.tel_mobile}
        citizen_no={props.selectedPatient?.citizen_passport || props.selectedPatient?.citizen_no}
        coverage={props.selectedPatient?.coverage}
        payer={props.selectedPatient?.payer_name}
        religion={props.selectedPatient?.religion}
        checkout={props.selectedEmr?.checkout_cause_name || props.selectedEmr?.checkout_cause_label}
        remark={
          props?.CreateUpdateEncounterSequence?.encounterList?.filter(
            (item: any) => item.number === props.selectedPatient?.encounter_no
          )?.[0]?.remark || props.selectedEncounter?.remark
        }
        note={
          props?.CreateUpdateEncounterSequence?.encounterList?.filter(
            (item: any) => item.number === props.selectedPatient?.encounter_no
          )?.[0]?.note || props.selectedEncounter?.note
        }
        //PatienPanel
        underlyingDisease={underlyingDisease}
        selectedADR={ADR}
        weigth={
          props?.weightInfo?.weight !== undefined
            ? `${props?.weightInfo?.weight}  ${props?.weightInfo?.weight_unit}`
            : null
        }
        height={
          props?.heightInfo?.height !== undefined
            ? `${props?.heightInfo?.height}  ${props?.heightInfo?.height_unit}`
            : null
        }
        vitalSign={vitalSignList}
        labResult={drugLabInteraction}
        OnADR={() => setOpenModSelectedADR(true)}
        OnUnderlyingDisease={() => setOpenModSelectedUD(true)}
        OnLab={() => setOpenModSelectedLAB(true)}
        OnVitalSign={() => setOpenModSelectedVS(true)}
        // telemed={null}
        ButtonSuspending={
          ["การเงิน"].includes(props.divisionType || "") && (
            <CardSuspending
              onEvent={props.onEvent}
              setProp={props.setProp}
              // seq
              runSequence={props.runSequence}
              MedicalFeeHistorySequence={props.MedicalFeeHistorySequence}
              // data
              patientId={props.selectedEncounter?.patient || props.selectedPatient?.id}
              encounterSuspension={props.encounterSuspension}
              // CommonInterface
              buttonLoadCheck={props.buttonLoadCheck}
              errorMessage={props.errorMessage}
              loadingStatus={props.loadingStatus}
              // options
              masterOptions={props.masterOptions}
              languageUX={props.languageUX}
            />
          )
        }
        // Assessment
        isolatePrecaution={handleSetIsolatePrecaution}
        fallRiskAssessment={handleSetFallRiskAssessment}
        tobacco={
          (tobaccoOptions || []).find(
            (data: any) => data.value === props.AssessmentSequence?.patientAssessment?.data?.tobacco
          )?.text
        }
        alcohol={
          (alcoholOptions || []).find(
            (data: any) => data.value === props.AssessmentSequence?.patientAssessment?.data?.alcohol
          )?.text
        }
        narcotic={
          (narcoticOptions || []).find(
            (data: any) =>
              data.value === props.AssessmentSequence?.patientAssessment?.data?.narcotic
          )?.text
        }
        mdrTriage={
          (mdrOptions || []).find(
            (data: any) =>
              data.value === props.AssessmentSequence?.patientAssessment?.data?.mdr_triage
          )?.text
        }
        //Allergy Status

        isHiddenPartialMenu={CONFIG.V3_HIDDEN_TAB_LIST.some(
          (i: any) => i === "submenu_patient_allergy"
        )}
        otherImage={
          statusLabel.other.icon && (
            <Image
              id="CardPatientPanel-Image-Status-สารอื่นๆ"
              src={statusLabel.other.icon}
              style={{ width: "35px", height: "35px" }}
              onClick={handleOpenModAllergyHistory("other")}
            />
          )
        }
        foodImage={
          statusLabel.food.icon && (
            <Image
              id="CardPatientPanel-Image-Status-อาหาร"
              src={statusLabel.food.icon}
              style={{ width: "35px", height: "35px" }}
              onClick={handleOpenModAllergyHistory("food")}
            />
          )
        }
        drugImage={
          statusLabel.drug.icon && (
            <Image
              id="CardPatientPanel-Image-Status-ยา"
              src={statusLabel.drug.icon}
              style={{ width: "35px", height: "35px" }}
              onClick={handleOpenModAllergyHistory("drug")}
            />
          )
        }
        btnBindId={
          <ButtonBindId
            onEvent={props.onEvent}
            setProp={props.setProp}
            chatDetail={props.chatDetail}
            selectedEncounter={props.selectedEncounter}
            // CommonInterface
            loadingStatus={props.loadingStatus}
            selectedPatient={props.selectedPatient}
            selectedAdmitOrderRoomItem={props.selectedAdmitOrderRoomItem}
            errorMessage={props.errorMessage}
          />
        }
        statusAndAction={
          <div
            style={{
              display: "grid",
              justifyContent: "flex-end",
              marginTop: "-26.5px",
            }}
          >
            {props.divisionType !== "ห้องยา" && (
              <PatientStatusActionButton
                onEvent={props.onEvent}
                setProp={props.setProp}
                // seq
                runSequence={props.runSequence}
                // controller
                cardDischargeController={props.cardDischargeController}
                // data
                patientData={props.patientData}
                loginVia={props.loginVia}
                dischargeDoctor={props.dischargeDoctor}
                approve_by={props.approve_by}
                medReconcileIndex={props.medReconcileIndex}
                selectedShippingOrder={props.selectedShippingOrder}
                selectedProgressCycle={props.selectedProgressCycle}
                // CoomonInterface
                errorMessage={props.errorMessage}
                orDischargeResult={props.orDischargeResult}
                selectedEncounter={props.selectedEncounter}
                selectedAppointment={props.selectedAppointment}
                selectedPatient={props.selectedPatient}
                selectedAdmitOrderRoomItem={props.selectedAdmitOrderRoomItem}
                selectedMainOrOrder={props.selectedMainOrOrder}
                selectedEmr={props.selectedEmr}
                loadingStatus={props.loadingStatus}
                patientEmr={props.patientEmr}
                django={props.django}
                isStudentUser={props.isStudentUser}
                checkApprove={props.checkApprove}
                // options
                masterOptions={props.masterOptions}
                medicalHistoryList={props.medicalHistoryList}
                languageUX={props.languageUX}
              />
            )}
          </div>
        }
        actionButton={
          !["VERIFIED", "NOT_VERIFIED"].includes(props.selectedPatient?.profile_status || "") && (
            <ButtonBindId
              onEvent={props.onEvent}
              setProp={props.setProp}
              chatDetail={props.chatDetail}
              selectedEncounter={props.selectedEncounter}
              // CommonInterface
              loadingStatus={props.loadingStatus}
              selectedPatient={props.selectedPatient}
              selectedAdmitOrderRoomItem={props.selectedAdmitOrderRoomItem}
              errorMessage={props.errorMessage}
              bindHn={true}
            />
          )
        }
        isShowPregnancy={pregnancyStatus}
        pregnancyImage={pregnancyImage}
        languageUX={props.languageUX}
      />
      <WebcamProfile
        open={openWebCam}
        onClose={() => setOpenWebCam(false)}
        setNewImage={handleSaveNewImage}
        languageUX={props.languageUX}
      />

      {openModNote && (
        <ModConfirm
          openModal={openModNote}
          titleName={intl.formatMessage({ id: "กรอกข้อมูลที่ต้องการบันทึกเพิ่มเติม" })}
          titleColor={"blue"}
          textContent={<span style={{ fontWeight: "bold" }}> Note</span>}
          onApprove={handleNoteConfirmed}
          denyButtonColor="red"
          approveButtonColor="blue"
          basic={true}
          onDeny={handleNoteDeny}
          showTextNote={true}
          setTextNote={(e: any, data: any) => {
            props.setProp("AllergySequence.actionNote", data.value);
          }}
          textNote={props.AllergySequence?.actionNote || ""}
          size={"tiny"}
          errorMessageText={props.errorMessage?.[CARD_ALLERGY]}
        />
      )}

      <Modal open={openModSelectedADR} size="large" onClose={handleCloseModSelectedADR}>
        <ModAllergyUX
          AllergyList={allergyListMod}
          options={[
            { key: 1, value: 1, text: "ผู้ป่วยแจ้ง" },
            { key: 2, value: 2, text: "ญาติผู้ป่วยแจ้ง" },
            { key: 3, value: 3, text: "มาจากโรงพยาบาลอื่น" },
          ]}
          note={props.AllergySequence?.adrSelected?.newNote}
          onChangeNote={handleOnChangeNoteADR}
          OnAddADR={() =>
            props.runSequence({
              sequence: "Allergy",
              action: "newNote",
              card: CARD_PATIENT_PANEL,
            })
          }
        />
      </Modal>

      <Modal open={openModSelectedUD} size="large" onClose={handleCloseModSelectedUD}>
        <CardUnderlyingDiseaseUX
          encounter={props.selectedEncounter?.pk}
          underlyingDiseaseList={underlyingDiseaseList}
          message={props.messageProblemDPO}
          onChangeData={handleChangeMessageProblemDPO}
          onAddData={() => {
            props.onEvent({
              message: "HandleAddMessageProblemDPO",
              params: {
                action: "createProblemList",
                patient: props.selectedEncounter?.patient,
              },
            });
          }}
          languageUX={props.languageUX}
        />
      </Modal>

      <Modal open={openModSelectedLAB} size="large" onClose={handleCloseModSelectedLAB}>
        <Modal.Header> Lab Result</Modal.Header>
        <div style={{ padding: "5px" }}>
          <Table
            data={labData}
            headers="Code,Name,Value,Unit,Result Date"
            keys="code,name,value,unit,result_date"
            minRows="10"
            showPagination={false}
            style={{ height: "300px" }}
          ></Table>
        </div>
      </Modal>

      <Modal open={openModSelectedVS} size="large" onClose={handleCloseModSelectedVS}>
        <CardVitalSign
          closeable={props?.closeable}
          toggleable={props?.toggleable}
          callOnDidMount={props?.callOnDidMount}
          useCheckboxDateManual={true}
          vitalSignTypeByAge={props.selectedPatient?.age}
          vitalSignTypeByEncounter={props.selectedEncounter?.id}
          defaultDateFilter={props.selectedEncounter?.type === "IPD"}
          onGetLastHeight={props?.onGetLastHeight}
          onGetVitalSignType={props?.onGetVitalSignType}
          onGetVitalSign={props?.onGetVitalSign}
          onNewMeasurement={props?.onNewMeasurement}
          onUpdateVitalsignValue={props?.onUpdateVitalsignValue}
          onDeleteMeasurement={props?.onDeleteMeasurement}
          languageUX={props.languageUX}
        />
      </Modal>

      <Modal open={!!actionLogId}>
        <CardAllergyActionLog
          id={actionLogId}
          runSequence={props.runSequence}
          actionLogData={actionLogData}
          onDismiss={() => {
            setActionLogId(null);
          }}
          languageUX={props.languageUX}
        />
      </Modal>

      <Modal open={openModADRNote} onClose={handleCloseModADRNote}>
        <CardAdverseReactionNote
          searchedItemList={props.searchedItemList}
          masterOptions={props.masterOptions}
          onCancelNewADRNote={handleCancelNewADRNote}
          onSaveNewADRNote={handleSaveNewADRNote}
          AllergySequence={props.AllergySequence}
          onEvent={props.onEvent}
          setProp={props.setProp}
          adrSelected={props.AllergySequence?.adrSelected}
          languageUX={props.languageUX}
        />
      </Modal>

      {openModNote && (
        <ModConfirm
          openModal={openModNote}
          titleName={intl.formatMessage({ id: "กรอกข้อมูลที่ต้องการบันทึกเพิ่มเติม" })}
          titleColor={"blue"}
          textContent={<span style={{ fontWeight: "bold" }}> Note</span>}
          onApprove={handleNoteConfirmed}
          denyButtonColor="red"
          approveButtonColor="blue"
          basic={true}
          onDeny={handleNoteDeny}
          showTextNote={true}
          setTextNote={(e: any, data: any) => {
            props.setProp("AllergySequence.actionNote", data.value);
          }}
          textNote={props.AllergySequence?.actionNote || ""}
          size={"tiny"}
          errorMessageText={props.errorMessage?.[CARD_ALLERGY]}
        />
      )}

      <Modal
        size="mini"
        open={openEditProblemList}
        onClose={() => {
          setOpenEditProblemList(false);
        }}
      >
        <Segment inverted className="yellow" style={{ margin: "0px", fontWeight: "bold" }}>
          แก้ไขโรคประจำตัว
        </Segment>
        <Modal.Content>
          <Form>
            <Form.Field width={16}>
              <label>{intl.formatMessage({ id: "โรคประจำตัว" })}</label>
            </Form.Field>

            <Form.Field width={16}>
              <textarea
                rows={3}
                style={{ resize: "none" }}
                value={textEdit || ""}
                onChange={(v: any) => {
                  setTextEdit(v.target.value);
                }}
              />
            </Form.Field>
          </Form>
          <div
            style={{
              margin: "15px 20% 0px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              color="green"
              onClick={() => {
                props.onEvent({
                  message: "HandleAddMessageProblemDPO",
                  params: {
                    action: "editProblemList",
                    pk: dataProblemList.pk,
                    patient: dataProblemList.patient,
                    message: textEdit,
                  },
                });
                setOpenEditProblemList(false);
              }}
            >
              ใช่
            </Button>
            <Button
              color="red"
              onClick={() => {
                setOpenEditProblemList(false);
              }}
            >
              ไม่
            </Button>
          </div>
        </Modal.Content>
      </Modal>

      <Modal
        size="large"
        open={openPregnancyLog}
        onClose={() => {
          setOpenPregnancyLog(false);
        }}
      >
        <div style={{ fontWeight: "bold", fontSize: "large", padding: "20px" }}>
          ประวัติการทำงาน
        </div>
        <div>
          <Table
            data={pregnancyLog}
            headers="วันที่/เวลา,รายละเอียด,ผู้บันทึก,แผนกที่บันทึก"
            keys="edited,tracked_data_detail,name,division_name"
            minRows="5"
            showPagination={false}
            style={{ padding: "20px", height: "400px" }}
          ></Table>
        </div>
      </Modal>

      <Modal
        open={!!modAllergyHistory}
        closeOnDimmerClick={true}
        onClose={handleCloseModAllergyHistory}
      >
        <CardSubAllergyHistory
          // seq
          runSequence={props.runSequence}
          AllergySequence={props.AllergySequence}
          type={modAllergyHistory}
          ardList={(groupADR as any)[modAllergyHistory]}
          DJANGO={props.DJANGO}
          selectedDivision={props.selectedDivision}
          // options
          masterOptions={props.masterOptions}
          // config
          modal={true}
          // callback
          onShowInactive={handleShowInactive}
          languageUX={props.languageUX}
        />
      </Modal>
    </div>
  );
};

const ButtonToggleTabMenu = (props: any) => {
  const intl = useIntl()
  const handleHideTabMenu = () => {
    props.setHideTabMenu?.((hideTabMenu: boolean) => !hideTabMenu);
  };

  return typeof props.hideTabMenu !== "undefined" ? (
    <div
      style={{
        backgroundColor: "var(--primary-theme-chat-half-light)",
        display: "grid",
        gridTemplateColumns: "10% 1fr 10%",
        cursor: "pointer",
        height: "30px",
        alignItems: "center",
      }}
      onClick={handleHideTabMenu}
    >
      <div></div>
      <div
        id="CardPatientPanel-div-HideTabMenu"
        style={{
          textAlign: "center",
          color: "#746a6a",
          fontWeight: "bold",
        }}
      >
        {props.hideTabMenu ? intl.formatMessage({id: "แสดงเมนู"}) : intl.formatMessage({id: "ซ่อนเมนู"}) }
      </div>
      <div>
        <Icon
          name={`triangle ${props.hideTabMenu ? "left" : "right"}`}
          size="large"
          style={{ margin: "-3px -5px 0", color: "#746a6a" }}
        />
      </div>
    </div>
  ) : null;
};

export default CardPatientPanel;
